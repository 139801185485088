import { Reason } from "../types/Reason";

export interface ReasonData {
  reason: Reason;
  label: string;
  icon: string;
  description?: string;
  subReasons?: ReasonData[];
}

export const insurances: ReasonData[] = [
  { reason: "home_insurance", label: "Vivienda", icon: "home" },
  { reason: "vehicle_insurance", label: "Vehículo", icon: "directions_car" }
];

export const credits: ReasonData[] = [
  { reason: "home_credit", label: "Vivienda", icon: "home" },
  { reason: "vehicle_credit", label: "Vehículo", icon: "directions_car" },
  { reason: "consumer_credit", label: "Consumo", icon: "shopping_cart" },
  { reason: "educational_credit", label: "Educación", icon: "school" },
  { reason: "commercial_credit", label: "Libre inversión", icon: "store" },
  { reason: "credit_card", label: "Tarjeta de crédito", icon: "credit_card" }
];

export const saves: ReasonData[] = [
  { reason: "savings_account", label: "Cuenta ahorro", icon: "savings" },
  { reason: "debit_card", label: "Tarjeta débito", icon: "credit_card" },
  {
    reason: "scheduled_savings",
    label: "Ahorro programado",
    icon: "calendar_clock"
  },
  { reason: "investment", label: "Inversión", icon: "monitoring" }
];

export const generals: ReasonData[] = [
  { reason: "curiosity", label: "Por curiosidad", icon: "domino_mask" },
  {
    reason: "get_credit",
    label: "Quiero adquirir un crédito",
    icon: "credit_card_heart",
    description: "¿Qué tipo de crédito buscas?",
    subReasons: credits
  },
  {
    reason: "credit_rejected",
    label: "Me rechazaron un crédito",
    icon: "credit_card_off",
    description: "¿Qué tipo de crédito te rechazaron?",
    subReasons: credits
  },
  {
    reason: "increase_score",
    label: "Quiero aumentar mi puntaje",
    icon: "readiness_score"
  },
  {
    reason: "reported_doesnt_known_reason",
    label: "Estoy reportado y no conozco la razón",
    icon: "person_raised_hand"
  }
];
