import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { getHref } from "../helpers/apiWhatsApp";
import { capitalizeEachWord } from "../helpers/capitalize";
import isSubscribed from "../helpers/isSubscribed";
import jwt from "../helpers/jwt";
import { ReactComponent as Whatsapp } from "../images/whatsapp.svg";
import { AppState } from "../store/store";
import styled, { css } from "../styles/styled-components";

const SideButtonsStyled = styled.div`
  position: fixed;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  display: flex;
  z-index: 2000;
  color: transparent;
  flex-direction: column;

  :hover {
    color: ${props => props.theme.red};
    font-size: 17px;
  }
`;
const SideButton = css`
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  text-decoration: none;
  margin: 0.5rem 0;
  height: 3.125rem;
  width: 2.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07);
  fill: #fff;
  .fill-color: {
    fill: #fff;
  }
  stroke: #fff;
  .stroke-color: {
    stroke: #fff;
  }
`;
const Button2 = styled.a`
  ${SideButton}
  background-color: #25d366;
  margin-left: 5px;
`;

interface Props {
  user: {
    name: string;
    uuid: string;
    document: string;
    documentType: string;
  };
  token: string;
  showWppButton: boolean;
}

const SideButtons = ({ user, token, showWppButton }: Props) => {
  const [msg, setMsg] = useState("");
  const pathname = location.pathname;
  const userIsSubscribed = isSubscribed();
  const decodedToken = jwt.decode(token);
  const invalidUser = decodedToken.ignore;

  const data = [
    { label: "Nombre", value: capitalizeEachWord(user.name) },
    { label: user.documentType, value: user.document },
    { label: "Usuario", value: user.uuid }
  ];

  useEffect(() => {
    if (["/planes", "/confirmar/compra"].some(path => path === pathname) || pathname.startsWith("/comprar/")) {
      setMsg("Necesito asesoría con los servicios");
    } else if (["/validacion-identidad", "/validacion"].some(path => path === pathname)) {
      setMsg("Necesito ayuda para validar mi identidad");
    } else {
      setMsg("Necesito ayuda");
    }
  }, [pathname]);

  return (
    <>
      {token && userIsSubscribed && showWppButton && !invalidUser && (
        <SideButtonsStyled>
          <Button2
            href={getHref(msg, data)}
            target="_black"
          >
            <Whatsapp />
          </Button2>
        </SideButtonsStyled>
      )}
    </>
  );
};


const mapStateToProps = (state: AppState) => ({
  user:
    state.user.status === "loaded"
      ? {
        uuid: state.security.userId,
        document: state.user.payload.documentNumber,
        documentType: state.user.payload.documentType,
        name: `${state.user.payload.name} ${state.user.payload.lastname}`,
      }
      : {
        uuid: "",
        name: "",
        document: "",
        documentType: "",
      },
  showWppButton: state.ui.showWppButton,
  token: state.security.token,
});

export default connect(mapStateToProps)(SideButtons);
