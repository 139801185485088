import React, { useState } from "react";
import Icon from "../../../components/UI/Icon";
import { capitalize } from "../../../helpers/capitalize";
import { profileLabelByScore, scoreApproximate } from "../../../helpers/scoreLevels";
import { Report as ReportType } from "../../../services/useReportsSharedIdGet";
import styles from "./Report.module.scss";

interface Props {
  report: ReportType;
  collapsible?: boolean;
}

const Report = ({ report, collapsible }: Props) => {
  const [open, setOpen] = useState(!collapsible);
  const score = scoreApproximate(report.scoreRating) || 0;
  const summary = report.summary || [];
  const filteredSummary = summary.filter((obligacion) => obligacion.PaqueteInformacion !== "Subtotal Principal");
  const subtotal = summary.find((obligacion) => obligacion.PaqueteInformacion === "Subtotal Principal");

  return (
    <div className={styles.container}>
      <div className="d-md-none my-3 w-100">
        {collapsible
          ? (
            <button
              className={`btn btn-outline-secondary ${styles.button}`}
              onClick={() => setOpen(!open)}
            >
              Ver información personal
              <Icon icon={open ? "expand_less" : "expand_more"} />
            </button>
          )
          : <hr className={styles.hr} />
        }
      </div>
      <div className={`${styles.data} ${open ? styles.active : ""}`}>
        <div className={styles.section}>
          <div className={styles.section_title}>DATOS PERSONALES</div>
          <div className={styles.row}>
            <div className={styles.cell}>Nombre: {report.name} {report.lastname}</div>
            <div className={styles.cell}>Documento: CC {report.document} </div>
          </div>
          <div className={styles.row}>
            <div className={styles.cell}>Teléfono: {report.phone} </div>
            <div className={styles.cell}>Ciudad: {report.city}</div>
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.section_title}>PUNTAJE CREDITICIO</div>
          <div className={styles.score}>
            {capitalize(profileLabelByScore(score))}
            <div className={styles.source}>Fuente: TransUnion</div>
          </div>
        </div>
        <div className={styles.table_container}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Categoría</th>
                <th>Número de Obligaciones</th>
                <th>Saldo Total ($)</th>
                <th>Cuota al Día ($)</th>
                <th>Obligaciones en Mora</th>
                <th>Saldo en Mora ($)</th>
              </tr>
            </thead>
            <tbody>
              {filteredSummary.map((obligacion, index) => (
                <tr key={index}>
                  <td data-label="Categoría">{obligacion.PaqueteInformacion}</td>
                  <td data-label="Número de Obligaciones">{obligacion.NumeroObligaciones}</td>
                  <td data-label="Saldo Total ($)">{(obligacion.TotalSaldo * 1000).toLocaleString()}</td>
                  <td data-label="Cuota al Día ($)">{(obligacion.CuotaObligacionesDia * 1000).toLocaleString()}</td>
                  <td data-label="Obligaciones en Mora">{obligacion.CantidadObligacionesMora}</td>
                  <td data-label="Saldo en Mora ($)">{(obligacion.SaldoObligacionesMora * 1000).toLocaleString()}</td>
                </tr>
              ))}

              {subtotal && (
                <tr className={styles.total}>
                  <td data-label="Categoría">Suma Total</td>
                  <td data-label="Número de Obligaciones">{subtotal.NumeroObligaciones}</td>
                  <td data-label="Saldo Total ($)">{(subtotal.TotalSaldo * 1000).toLocaleString()}</td>
                  <td data-label="Cuota al Día ($)">{(subtotal.CuotaObligacionesDia * 1000).toLocaleString()}</td>
                  <td data-label="Obligaciones en Mora">{subtotal.CantidadObligacionesMora}</td>
                  <td data-label="Saldo en Mora ($)">{(subtotal.SaldoObligacionesMora * 1000).toLocaleString()}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div >
    </div >
  )
}

export default Report;