import React, { useState } from "react";
import { Button } from "reactstrap";
import Modal from "../../../components/UI/Modal";
import { generals, ReasonData } from "../../../dictionaries/reason";
import { track } from "../../../helpers/track";
import reasonPost from "../../../services/reasonPost";
import { Reason } from "../../../types/Reason";
import styles from "./ModalReason.module.scss";

interface Props {
  userId: string;
  onClose: VoidFunction;
}

const ModalReason = ({ userId, onClose }: Props) => {
  const [intent, setIntent] = useState<Reason>();
  const [options, setOptions] = useState(generals);
  const [description, setDescription] = useState("¿Qué te motivó a registrarte en Enbanca?");

  const selectReason = async ({ reason, description, subReasons }: ReasonData) => {
    if (subReasons) {
      setIntent(reason);
      setOptions([...subReasons]);
      description && setDescription(description);
    } else {
      const properties = { reason, intent };
      track({ userId, event: "REASON", properties });
      await reasonPost({ reason });
      onClose();
    }
  }

  return (
    <Modal centered>
      <h4 className={styles.title}>{description}</h4>
      {!intent &&
        <p className={styles.description}>
          Queremos saber qué te impulsó a unirte.
        </p>
      }
      <div className={styles.options}>
        {options.map((reasonData) => (
          <Button
            color="primary"
            onClick={() => selectReason(reasonData)}
            className={styles.option}
          >
            {reasonData.label}
          </Button>
        ))}
      </div>
      <p className={styles.description}>
        Tendremos en cuenta esta información para brindarte
        contenido y oportunidades acordes a tus necesidades.
      </p>
    </Modal>
  )
}

export default ModalReason;
