import { Product } from "../services/useReportsDetailGet";

interface ProductMetadata {
  singular: string;
  plural: string;
  icon: string;
}

export const products: Product[] = [
  "accounts",
  "credit-card",
  "credit",
  "contracts-services"
];

export const productMetadata: Record<Product, ProductMetadata> = {
  accounts: {
    singular: "Cuenta",
    plural: "Cuentas",
    icon: "account_balance_wallet"
  },
  "credit-card": {
    singular: "Tarjeta de crédito",
    plural: "Tarjetas de crédito",
    icon: "credit_card"
  },
  credit: { singular: "Crédito", plural: "Créditos", icon: "account_balance" },
  "contracts-services": {
    singular: "Contrato o servicio",
    plural: "Contratos y servicios",
    icon: "receipt_long"
  }
};
