import * as jwt from 'jsonwebtoken';
import Rol from '../enums/Rol';

export interface Token {
  roles: Rol[];
  sub: string;
  pex: string;
  cpl: string;
  exp: number;
  sqv: boolean;
  eid?: boolean;
  rvp?: boolean;
  ignore:boolean;
}

export const encode = (
  rol: Rol[],
  exp: number,
  uuid?: string,
  eid?: boolean,
  rvp?: boolean
) => {
  const payload = {
    roles: rol,
    sub: uuid || 'fake-uuid',
    exp,
    eid,
    rvp
  };
  return jwt.sign(payload, 'xxxxxx');
};

export const decode = (token: string | undefined): Token => {
  const defaultToken = {
    roles: [],
    sub: '',
    pex: '',
    cpl: '',
    sqv:false,
    exp: Math.round(new Date().getTime() / 1000),
    ignore:false
  };
  const decoded = jwt.decode(token || '') as any;

  if (!decoded) {
    return defaultToken;
  }

  return {
    roles: decoded.roles,
    sub: decoded.sub,
    pex: decoded.pex,
    cpl: decoded.cpl,
    eid: decoded.eid,
    rvp: decoded.rvp,
    exp: decoded.exp,
    sqv: decoded.sqv,
    ignore:decoded.ignore
  };
};

export default {
  encode,
  decode
};
