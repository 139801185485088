import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Data, getHref } from "../../../../helpers/apiWhatsApp";
import { capitalizeEachWord } from "../../../../helpers/capitalize";
import { AppState } from "../../../../store/store";
import styles from "./InvalidUser.module.scss";

interface Props {
  user: {
    name: string;
    uuid: string;
    document: string;
    documentType: string;
  };
}

const InvalidUser = ({ user }: Props) => {
  const msg = "Deseo verificar mi cuenta";
  const [data, setData] = useState<Data[]>([]);

  useEffect(() => {
    setData([
      { label: "Nombre", value: capitalizeEachWord(user.name) },
      { label: user.documentType, value: user.document },
      { label: "Usuario", value: user.uuid },
    ])
  }, [user]);

  return (
    <div className={styles.content}>
      <div className={styles.message}>
        <p className={styles.notice}>
          <strong>
            No pudimos completar tu validación de identidad.
            Para continuar con el proceso, por favor comunícate con uno de nuestros agentes para realizar la validación manual.
          </strong>
        </p>
        <p className={styles.text}>
          Nuestro horario de atención es de lunes a viernes, de 8:30 a.m. a 6:00 p.m.
        </p>
        <p className={styles.text}>
          Haz clic en el botón a continuación para hablar con un agente y completar tu validación:
        </p>
        <div>
          <a
            className="btn btn-primary"
            href={getHref(msg, data)}
            rel="noopener noreferrer"
            target="_blank"
            title="WhatsApp"
          >
            Continuar
          </a>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  user:
    state.user.status === "loaded"
      ? {
        name: `${state.user.payload.name} ${state.user.payload.lastname}`,
        uuid: state.security.userId,
        document: state.user.payload.documentNumber,
        documentType: state.user.payload.documentType,
      }
      : {
        name: "",
        uuid: state.security.userId,
        document: "",
        documentType: "",
      }
});
export default connect(mapStateToProps)(InvalidUser);
