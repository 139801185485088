import { Reason } from "../types/Reason";
import api from "./api";

export interface ReasonParams {
  reason: Reason;
}

export const url = "reason";

const reasonPost = async (params: ReasonParams) => {
  await api.post(url, true, { ...params });
};

export default reasonPost;
