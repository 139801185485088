import { ReportSummaryResponse } from "../services/reportsSummaryGet";
import { Product } from "../services/useReportsDetailGet";

type ReportSummaryByProduct = Pick<
  ReportSummaryResponse,
  "accounts" | "creditCards" | "credits" | "contractsAndServices"
>;

type ReportKeyMap = Record<Product, keyof ReportSummaryByProduct>;

export const reportKeyMap: ReportKeyMap = {
  accounts: "accounts",
  "credit-card": "creditCards",
  credit: "credits",
  "contracts-services": "contractsAndServices"
};
