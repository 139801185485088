import React from "react";
import { Button } from "reactstrap";
import Icon from "../../../../../components/UI/Icon";
import Modal from "../../../../../components/UI/Modal";
import styles from "./ModalOverdue.module.scss";

interface Props {
  onShow: VoidFunction;
}

const ModalOverdue = ({ onShow }: Props) => {
  return (
    <Modal centered>
      <div className={styles.box}>
        <div className={styles.banner}>
          <Icon
            icon="warning"
            className={styles.icon}
            fill
          />
        </div>
        <div className={styles.wrapper}>
          <p className={styles.title}>
            Tienes novedades con nuestros aliados
          </p>
          <p className={styles.description}>
            Te invitamos a revisar la sección de novedades para que
            las gestiones y conserves tu historial crediticio en buen estado.
          </p>
          <Button
            color="primary"
            onClick={onShow}
            className={styles.button}
          >
            Ver novevades
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalOverdue;