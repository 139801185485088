import React from "react";
import { products } from "../../../../dictionaries/products";
import { Product } from "../../../../services/useReportsDetailGet";
import styles from "./DashboardReport.module.scss";
import ProductReport from "./ProductReport";

interface ProductReport {
  icon: string;
  label: string;
  product: Product;
}

const DashboardReport = () => {
  return (
    <div id="dashboard_report" className="eb-card" style={{ scrollMarginTop: "5.25rem" }}>
      <div className={styles.container}>
        {products.map(product => (
          <ProductReport
            key={product}
            product={product}
          />
        ))}
      </div>
    </div>
  )
}

export default DashboardReport;