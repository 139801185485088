import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
import Icon from "../../../../components/UI/Icon";
import Loading from "../../../../components/UI/Loading";
import ProMeter from "../../../../components/UI/ProMeter";
import { capitalize } from "../../../../helpers/capitalize";
import { profileLabelByScore, rangeLabelByScore } from "../../../../helpers/scoreLevels";
import { Score as ScoreType } from "../../../../services/scoresGet";
import styles from "./Score.module.scss";

interface Props {
  plan: string;
  scores: ScoreType[];
}

interface ScoreData {
  icon: string;
  value: number;
  label: string;
  range: string;
  color: string;
  trend: number;
  previousScore: ScoreType | null;
}

const Score = ({ plan, scores }: Props) => {
  const [score, setScore] = useState<ScoreData | null>(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (scores.length) {
      const value = scores[0].puntaje;
      const label = capitalize(profileLabelByScore(value));
      const range = rangeLabelByScore(value);
      const previousScore = scores.length > 1 ? scores[1] : null;
      const trend = previousScore
        ? value - previousScore.puntaje
        : 0;
      let icon = "remove";
      let color = styles.equal;
      if (trend > 0) {
        icon = "arrow_upward_alt";
        color = styles.increase;
      }
      else if (trend < 0) {
        icon = "arrow_downward_alt";
        color = styles.decrease;
      }
      setScore({ icon, value, label, range, color, trend, previousScore });
    }
  }, [scores]);
  const handlePopupOpen = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const renderNoCreditScoreMessage = () => (
    <div className={styles.message}>
      <div className={styles.shape}>
        <Icon icon="readiness_score" />
      </div>
      <p className={styles.text}>
        Actualmente no cuentas con un score crediticio en Transunion
      </p>
      <NavLink to="/productos/todos" className="btn btn-primary">
        Empezar a construirlo
      </NavLink>
      <div className={styles.historyWhy} onClick={handlePopupOpen}>
        ¿Por qué no tengo historial crediticio?
      </div>
    </div>
  );

  const renderContent = () => {
    if (!score) return null;
    if (score.value < 0) return renderNoCreditScoreMessage();
    return null;
  };
  return (
    <div className={`eb-card ${styles.container}`}>
      {renderContent()}
      <div className={styles.header}>
        <p className={styles.title}>
          Puntaje de crédito
        </p>
        <div className={styles.subscription}>
          <p className={styles.plan}>
            {plan}
          </p>
          <Icon
            icon="check_circle"
            className={styles.icon}
            fill
          />
        </div>
      </div>
      {score
        ? (
          <>
            <ProMeter score={score.value < 0 ? 0 : score.value} />
            <div className={styles.info}>
              <div className={styles.element}>
                <p className={styles.text}>
                  {score.label}
                </p>
                <p className={styles.hint}>
                  {score.range}
                </p>
              </div>
              <div className={styles.element}>
                <div>
                  {score.previousScore && (
                    <p className={styles.text}>
                      {Math.abs(score.trend)} pts
                    </p>
                  )}
                  <Icon
                    icon={score.icon}
                    className={score.color}
                  />
                </div>
                <p className={styles.hint}>
                  Tendencia
                </p>
              </div>
            </div>
          </>
        )
        : <Loading />
      }
      {showPopup && (
        <div className={styles.popupOverlay} onClick={handlePopupClose}>
          <div className={styles.popupContent} onClick={(e) => e.stopPropagation()}>
            <div className={styles.concentClose}>
              <div className={styles.shapeIcon}>
                <Icon icon='search_insights' />
              </div>
              <div className={styles.closeButton} onClick={handlePopupClose}>
                <Icon icon='close' />
              </div>
            </div>
            <h4>¿Por qué no tengo score crediticio?</h4>
            <p>¡Aún no tienes score crediticio! Esto es normal si nunca has
              solicitado un crédito. ¡Pero no te preocupes! Es una gran
              oportunidad para empezar a construir un historial crediticio.
              puedes considerar opciones como solicitar una tarjeta de
              crédito o un pequeño préstamo, comenzando poco a poco.
              Tenemos aliados que pueden ayudarte a explorar tus primeras
              opciones de crédito, pero recuerda que la aprobación dependerá
              de los requisitos de cada entidad.
            </p>
            <NavLink
              to={`/productos/todos`}
              className="btn btn-primary"
            >
              Empezar a construirlo
            </NavLink>
          </div>
        </div>
      )}
    </div>
  )
}

export default Score;