import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loading from "../../../components/UI/Loading";
import { products } from "../../../dictionaries/products";
import { reportKeyMap } from "../../../dictionaries/reportKeyMap";
import isSubscribed from "../../../helpers/isSubscribed";
import jwt from "../../../helpers/jwt";
import { fetchReportSummary as fetchReportSummaryAction } from "../../../store/reportSummary/actions";
import { ReportSummaryState } from "../../../store/reportSummary/types";
import { AppState } from "../../../store/store";
import { UserState } from "../../../store/user/types";
import DashboardAlert from "../components/Alert/DashboardAlert";
import DashboardHeader from "../components/Header/DashboardHeader";
import InvalidUser from "../components/InvalidUser/InvalidUser";
import ModalReason from "../components/ModalReason";
import DashboardReport from "../components/Report/DashboardReport";
import DashboardScore from "../components/Score/DashboardScore";
import DashboardTools from "../components/Tools/DashboardTools";

interface Props {
  token: string;
  userId: string;
  userState: UserState;
  reportSummaryState: ReportSummaryState;
  fetchReportSummary(): void;
}

const DashboardContainer = ({ token, userId, userState, reportSummaryState, fetchReportSummary }: Props) => {
  const decodedToken = jwt.decode(token);
  const userIsSubscribed = isSubscribed();
  const invalidUser = decodedToken.ignore;
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    if ((!userIsSubscribed && !invalidUser) || !invalidUser) {
      fetchReportSummary();
    }
  }, []);

  useEffect(() => {
    if (reportSummaryState.status === "loaded") {
      const { payload } = reportSummaryState;
      setAlert(products.some(product => {
        const key = reportKeyMap[product];
        const productsSummary = payload[key] || [];
        return productsSummary.some(({ overdue }) => overdue);
      }))
    }
  }, [reportSummaryState.status])

  useEffect(() => {
    if (userState.status === "loaded") {
      const key = "_eb_reason_question_";
      const { payload } = userState;
      const { reasonQuestionAnswered } = payload;
      const opened = sessionStorage.getItem(key);
      if (!reasonQuestionAnswered && !opened) {
        setOpen(true);
        sessionStorage.setItem(key, "true");
      }
    }
  }, [userState.status])

  if ((!userIsSubscribed && !invalidUser) || !invalidUser) {
    if (reportSummaryState.status === "loading")
      return <Loading />

    if (reportSummaryState.status === "loaded" || reportSummaryState.status === "updating") {
      return (
        <>
          <DashboardHeader />
          {alert && <DashboardAlert />}
          <DashboardScore />
          <DashboardReport />
          <DashboardTools />
          {open && <ModalReason userId={userId} onClose={() => setOpen(false)} />}
        </>
      )
    }
  }
  if (!userIsSubscribed && invalidUser) {
    return (
      <>
        <DashboardScore />
        <DashboardTools />
      </>
    )
  }
  return (
    <>
      <InvalidUser />
      <DashboardTools />
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  userState: state.user,
  userId: state.security.userId,
  token: state.security.token || "",
  reportSummaryState: state.reportSummary,
});

const mapDispatchToProps = {
  fetchReportSummary: fetchReportSummaryAction
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);